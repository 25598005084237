import Vue from 'vue'
import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { fill, merge } from 'lodash'

interface PromotionNewState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  serverErrors: any;
  filering: boolean;

  object: any;
  report_data: any[];
  merchants: any[];
  cities: any[];
  products_list: any[];
  brands: any[];
  checkedRows: any[];
  merchant_categories: any[];
  form: any;
  categories: any[];
  subcategories: any[];

}

const $state: PromotionNewState = {
  loading: false,
  loaded: false,
  filering: false,
  fatalError: false,
  serverErrors: null,

  object: null,
  report_data: [],
  merchants: [],
  checkedRows: [],
  cities: [],
  products_list: [],
  brands: [],
  merchant_categories: [],
  form: {},
  categories: [],
  subcategories: [],
}


const initState = cloneDeep($state)


const EMPTY_OBJECT = {
  start_date: null,
  end_date: null,
  cities: [],
  products: [],
  name: null,
  short_description: null,
  conditions: null,
  image_upload_id: null,
}

const AVAILABLE_FIELDS = ['start_date', 'end_date', 'cities', 'products', 'name', 'short_description', 'conditions', 'category_id', 'image_upload_id']

export const $actions: ActionTree<PromotionNewState, RootState> = {

  initState({ commit, state }, { id, predefined }) {
    return new Promise((resolve, reject) => {
      if (id) {
        const vm = (this as any)._vm

        vm.$http
          .get(`/admins/rest/v1/promotion/${id}`)
          .then((response: AxiosResponse) => {
            commit('SET_FORM_DATA', {
              object: response.data.promotion,
            })
            commit('LOADED_SUCCESS')

            resolve(state.object)
          })
          .catch((error: AxiosError) => {
            // tslint:disable-next-line:no-console
            console.error('Fatal error', error.response?.data)
            commit('LOADED_ERROR')
            reject(error.response?.data)
          })
      }
      else {
        return new Promise((resolve, reject) => {
          commit('GENERATE_FORM_DATA', predefined)
          commit('LOADED_SUCCESS')
          resolve(state.object)
        })
      }
    })
  },


  // loadData({ commit, getters, dispatch }) {
  //     const vm = (this as any)._vm;

  //     return new Promise((resolve, reject) => {
  //         let params: any = {};

  //         commit('START_LOADING');

  //         vm.$http
  //             .get('/admins/rest/v1/reports/bonus_stats', {params: params})
  //             .then((response: AxiosResponse) => {
  //                 commit('SET_ITEMS_LIST', response.data);
  //                 commit('LOADED_SUCCESS');

  //                 resolve();
  //             })
  //             .catch((error: any) => {
  //                 // tslint:disable-next-line:no-console
  //                 console.error('Fatal error', error);
  //                 commit('LOADED_ERROR');
  //             });
  //     });
  // },

  prepareNew({ commit, state }, predefined) {
    return new Promise((resolve, reject) => {
      commit('GENERATE_FORM_DATA', predefined)
      commit('LOADED_SUCCESS')
      resolve()
    })
  },

  loadCities({ commit, state }, predefined) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/cities`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_CITIES', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
      vm.$http
        .get('/admins/rest/v1/product_categories')
        .then((response: AxiosResponse) => {
          commit('SET_CATEGORIES_LIST', response.data)
          commit('LOADED_SUCCESS')

          resolve()
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
      vm.$http
        .get('/admins/rest/v1/products')
        .then((response: AxiosResponse) => {
          commit('SET_PRODUCTS_LIST', response.data)
          commit('LOADED_SUCCESS')

          resolve()
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },

  // loadMerchantCategories({ commit, state }, predefined) {
  //   const vm = (this as any)._vm;
  //   return new Promise((resolve, reject) => {
  //     vm.$http
  //         .get(`/admins/rest/v1/merchant_categories`, {params:{per_page:1000}})
  //         .then((response: AxiosResponse) => {
  //             commit('SET_MERCHANT_CATEGORIES', response.data);
  //             // commit('LOADED_SUCCESS');
  //             // resolve();
  //         })
  //         .catch((error: AxiosError) => {
  //             // tslint:disable-next-line:no-console
  //             console.error('Fatal error', error.response?.data);
  //             commit('LOADED_ERROR');
  //             reject(error.response?.data);
  //         });
  //   });
  // },

  addAttachments({ state, commit }, newValue) {
    // state.form.icon_upload_id = newValue.id;
    // commit('CHANGE_VALUE', {field: 'image_document', newValue});
    commit('CHANGE_VALUE', { field: 'image_upload_id', newValue: newValue.id })
    console.log(newValue.id)

  },

  changeFormValue({ commit }, { field, newValue, index }) {
    commit('CHANGE_VALUE', { field, newValue, index })
  },

  changeFormValueProduct({ commit }, { field, newValue, index }) {
    commit('CHANGE_VALUE_PRODUCT', { field, newValue, index })
    commit('REMOVE_PRODUCT', { index })
  },

  deleteProduct({ commit }, { index }) {
    commit('REMOVE_PRODUCT', { index })
  },

  saveObject({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      console.log(state.form)


      const cleanForm: any = {}
      for (const key of AVAILABLE_FIELDS) {
        cleanForm[key] = state.form[key]
      }

      commit('START_LOADING')

      let request = null

      if (id) {
        request = vm.$http.patch(`/admins/rest/v1/promotion/${id}`, {
          promotion: cleanForm,
        })
      }
      else {
        request = vm.$http.post(`/admins/rest/v1/promotion`, {
          promotion: cleanForm,
        })
      }

      console.log(cleanForm)


      request
        .then((response: AxiosResponse) => {
          commit('LOADED_SUCCESS')

          resolve(response.data)
        })
        .catch((errorResponse: AxiosError) => {
          // eslint-disable-next-line
          console.error('Fatal error', errorResponse)

          if (errorResponse.response?.status === 500) {
            commit('LOADED_ERROR')
            reject()
          }
          else {
            const serverError = errorResponse.response?.data?.error

            commit('LOADED_SUCCESS')
            commit('SET_SERVER_ERROR', serverError)
            reject(serverError)
          }
        })
    })
  },
}

const compare: any = ((a: any, b: any) => {
  if (a.name > b.name) {
    return 1
  }
  if (a.name < b.name) {
    return -1
  }
  return 0
})

export const $mutations: MutationTree<PromotionNewState> = {
  RESET_STATE: (state) => {
    for (const key in initState) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },
  SET_ITEMS_LIST(state, data) {
    state.report_data = data.data
    const a = 0
    const merchants = state.object ? state.object.merchants : []
    state.form.merchants = []
    for (const i in merchants) {
      for (const j in state.report_data) {
        if (merchants[i].id === state.report_data[j].merchant_id) {
          state.form.merchants.push(state.report_data[j])
        }
      }
    }
  },
  SET_CATEGORIES_LIST(state, data) {
    const categories = []
    const subcategories = []
    for (let i = 0; i < data.product_categories.length; i++) {
      if (data.product_categories[i].parent_id === null) {
        categories.push(data.product_categories[i])
      }
      else {
        subcategories.push(data.product_categories[i])
      }
    }
    state.subcategories = subcategories
    state.categories = categories
  },
  SET_PRODUCTS_LIST(state, data) {
    state.products_list = data.products
  },
  SET_FORM_DATA(state, { object }) {
    state.object = object

    const formData = cloneDeep(state.object)

    state.form = {
      ...formData,
    }
  },
  SET_CITIES: (state, { cities }) => {
    state.cities = cities
  },

  GENERATE_FORM_DATA(state, predefined) {
    state.form = {
      ...cloneDeep(EMPTY_OBJECT),
      ...predefined,
    }
  },

  CHANGE_VALUE(state: PromotionNewState, { field, newValue }) {
    state.form[field] = newValue
  },

  CHANGE_VALUE_PRODUCT(state: PromotionNewState, { field, newValue, index }) {
    state.form.products = newValue
    console.log(state.form.products[0])

  },

  REMOVE_PRODUCT(state: PromotionNewState, { index }) {
    state.form.products.splice(index, 1)
  },

  ON_FORM_SAVE(state, { object }) {
    state.object = object

    const formData = cloneDeep(object)

    state.form = {
      ...formData,
    }
  },

}

export const $getters: GetterTree<PromotionNewState, RootState> = {}

export const STORE_KEY = '$_promotions_new'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
