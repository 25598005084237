












































































































































































































































































































































































































































































































































































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import {
  required,
  requiredIf,
  minLength,
  sameAs,
} from 'vuelidate/lib/validators'
import { getFieldError } from '@/core/tools/forms/get_field_error'
import { AxiosResponse, AxiosError } from 'axios'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'
// import { BTable } from 'bootstrap-vue/esm/components/table'

@Component({
  mixins: [validationMixin],
  computed: {
    ...mapState(STORE_KEY, [
      'loaded',
      'loading',
      'fatalError',
      'serverErrors',
      'form',
      'formDirty',
      'cities',
      'categories',
      'subcategories',
      'products_list',
    ]),
    ...mapActions(STORE_KEY, ['loadCities']),
  },
  methods: {
    getFieldError,
  },
  validations() {
    const rules: any = {
      start_date: { required },
      end_date: { required },
      cities: { required },
      name: { required },
      patterns: {},
      short_description: { required },
      conditions: { required },
      category_id: { required },
      image_upload_id: { required },
      products: { required },
      nextName: {},
    }
    return {
      form: rules,
    }
  },
  components: {
    TitleBar,
    // BTable
  },
})
export default class CardSeriesForm extends Vue {
  public selectedOptions: any = []
  public selectedOptionsCat: any = []
  public selectedCategory: any = []
  public nextName: any = ''
  public nextPatterns: any = ''
  public prod: any = []
  public productItem: any = []
  public productIndex: any = null
  public nextId: any = 0
  public show: any = false
  public showItem: any = false
  public dropFiles: any = null
  public productDropFile: any = null
  public productDropFilesId: any = null
  public subCattegories: any = []

  get titleStack() {
    return ['Акции', 'Новая акция']
  }

  public openProductItem(item: any, index: any) {
    this.productItem = item
    this.productIndex = index
  }

  public subList(id: any, subcategories: any) {
    this.subCattegories = []
    for (let i = 0; i < subcategories.length; i++) {
      if (subcategories[i].parent_id === id) {
        this.subCattegories.push(subcategories[i])
      }
    }
  }

  public changeValue(value: any) {
    this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
      field: 'cities',
      newValue: value,
    })

    // @ts-ignore
    this.$v.form.cities.$touch()
  }

  public changeValueCategory(value: any) {
    this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
      field: 'sub_category',
      newValue: value,
    })
    // @ts-ignore
    // this.$v.form["sub_category"].$touch();
  }

  public removeItem(index: any) {
    this.$store.dispatch(`${STORE_KEY}/deleteProduct`, { index })
  }

  public addExistingProducts(value: any) {
    this.prod.push({
      id: value.id,
      name: value.name,
      sub_category: value.sub_category,
      product_image_id: value.product_image_id,
    })


    this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
      field: 'products',
      newValue: this.prod,
    })
  }

  public changeValueProducts() {
    // @ts-ignore
    // this.$v.form2.$touch();

    if (this.nextName && this.productDropFile && this.selectedOptionsCat.length > 0) {
      this.prod.push({
        name: this.nextName,
        patterns: this.nextPatterns,
        sub_category: this.selectedOptionsCat,
        product_image_id: this.productDropFilesId,
      })

      this.nextName = ''
      this.nextPatterns = ''
      this.selectedOptionsCat = []
      this.productDropFilesId = null
      this.productDropFile = null
      this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
        field: 'products',
        newValue: this.prod,
      })


      // @ts-ignore
      // this.$v.form2[field].$touch();
      this.show = false
    }


  }

  public changeIntemProduct(index: any) {

    if (this.productItem.name
      && this.productItem.sub_category.length > 0 && this.productItem.product_image_id) {

      if (this.productDropFilesId) {
        this.prod.push({
          id: this.productItem.id,
          name: this.productItem.name,
          patterns: this.productItem.patterns,
          sub_category: this.productItem.sub_category,
          product_image_id: this.productDropFilesId,
        })
      }
      else {
        this.prod.push({
          id: this.productItem.id,
          name: this.productItem.name,
          patterns: this.productItem.patterns,
          sub_category: this.productItem.sub_category,
          product_image_id: this.productItem.product_image_id,
        })
      }

      this.productItem = []

      this.$store.dispatch(`${STORE_KEY}/changeFormValueProduct`, {
        field: 'products',
        newValue: this.prod,
        index,
      })

      this.showItem = false
    }


  }

  public changeValueProductsAndContinue() {

    if (this.nextName && this.productDropFile && this.selectedOptionsCat.length > 0) {
      this.prod.push({
        name: this.nextName,
        sub_category: this.selectedOptionsCat,
        product_image_id: this.productDropFilesId,
      })

      this.nextName = ''
      this.selectedOptionsCat = []
      this.productDropFilesId = null
      this.productDropFile = null
      this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
        field: 'products',
        newValue: this.prod,
      })

    }


  }

  public uploadFile(files: any) {
    const formData = new FormData()

    formData.append('file', files)

    // @ts-ignore
    this.$http
      .post(`/admins/rest/v1/uploads`, formData, {
        // emulateJSON: true,
        // headers: {
        //   'Content-Disposition': `form-data; name="photo"; filename="${file.name}"`
        // }
      })
      .then((response: any) => {
        this.$store.dispatch(
          `${STORE_KEY}/addAttachments`,
          response.data.upload,
        )
        this.dropFiles = response.data.upload
      })

    // @ts-ignore
    this.$v.form.image_upload_id.$touch()
  }

  public uploadFileProduct(files: any) {
    const formData = new FormData()
    console.log(files)

    formData.append('file', files)

    // @ts-ignore
    this.$http
      .post(`/admins/rest/v1/uploads`, formData, {
        // emulateJSON: true,
        // headers: {
        //   'Content-Disposition': `form-data; name="photo"; filename="${file.name}"`
        // }
      })
      .then((response: any) => {
        this.productDropFilesId = response.data.upload.id
        this.productDropFile = response.data.upload
      })

    // @ts-ignore
    // this.$v.form2.product_image_id.$touch();
  }

  public submitObject() {
    // @ts-ignore
    this.$v.form.$touch()

    if (!this.$v.form.$error) {
      this.$store
        .dispatch(`${STORE_KEY}/saveObject`, this.$route.params.id)
        .then((result) => {
          this.$buefy.snackbar.open(`Данные успешно сохранены`)
          this.$router.push({ name: 'promotion-list' })
        })
        .catch((e) => {
          console.log(1, e)
          const msg = `При сохраненит возникли проблемы. Попробуйте позже.`
          this.$buefy.snackbar.open({
            type: 'is-warning',
            message: msg,
          })
        })
    }
  }

  public fieldsValue(field: string, value: any) {
    this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
      field,
      newValue: value,
    })

    // @ts-ignore
    this.$v.form[field].$touch()
  }

  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  @Watch('$route.query')
  public handleParamsChange() {
    this.$store.dispatch(`${STORE_KEY}/loadCities`)
    this.$store
      .dispatch(`${STORE_KEY}/initState`, {
        id: this.$route.params.id,
        predefined: this.$route.query,
      })
      .then((result) => {
        this.prod = result.products
      })
  }

  public mounted() {
    this.handleParamsChange()
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }
}
