var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('title-bar',{attrs:{"title-stack":_vm.titleStack}}),_c('section',{staticClass:"section is-main-section"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"column is-one-third"},[_c('b-field',{attrs:{"label":"Название акции","type":_vm.$v.form.name.$dirty
              ? _vm.$v.form.name.$error
                ? 'is-danger'
                : 'is-success'
              : null,"message":_vm.getFieldError('name')}},[_c('b-input',{attrs:{"value":_vm.form.name},on:{"input":function($event){return _vm.fieldsValue('name', $event)}}})],1),_c('b-field',{attrs:{"label":"Краткое описание","type":_vm.$v.form.short_description.$dirty
              ? _vm.$v.form.short_description.$error
                ? 'is-danger'
                : 'is-success'
              : null,"message":_vm.getFieldError('short_description')}},[_c('b-input',{attrs:{"value":_vm.form.short_description},on:{"input":function($event){return _vm.fieldsValue('short_description', $event)}}})],1),_c('b-field',{attrs:{"label":"Период проведения:"}},[_c('p',{staticStyle:{"font-weight":"bold","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.form.start_date)+" - "+_vm._s(_vm.form.end_date)+" ")])]),_c('b-field',[(!_vm.form.start_date || !_vm.form.end_date)?_c('div',{staticClass:"alert alert-light",staticStyle:{"text-align":"center","font-weight":"bold","font-size":"16px"}},[_c('p',[_vm._v("Выберите период проведения акции")])]):_vm._e(),(
              new Date(_vm.form.start_date) < new Date() &&
              new Date(_vm.form.end_date) > new Date()
            )?_c('div',{staticClass:"alert alert-success",staticStyle:{"text-align":"center","font-weight":"bold","font-size":"16px"}},[_c('p',[_vm._v("Активна")])]):_vm._e(),(
              new Date(_vm.form.start_date) > new Date() &&
              new Date(_vm.form.end_date) > new Date()
            )?_c('div',{staticClass:"alert alert-primary",staticStyle:{"text-align":"center","font-weight":"bold","font-size":"16px"}},[_c('p',[_vm._v("Не началась")])]):_vm._e(),(
              new Date(_vm.form.start_date) < new Date() &&
              new Date(_vm.form.end_date) < new Date()
            )?_c('div',{staticClass:"alert alert-warning",staticStyle:{"text-align":"center","font-weight":"bold","font-size":"16px"}},[_c('p',[_vm._v("В архиве")])]):_vm._e(),(new Date(_vm.form.start_date) > new Date(_vm.form.end_date))?_c('div',{staticClass:"alert alert-danger",staticStyle:{"text-align":"center","font-weight":"bold","font-size":"16px"}},[_c('p',[_vm._v("Период не верен")])]):_vm._e()]),_c('b-field',{attrs:{"label":"Дата проведения с:","type":_vm.$v.form.start_date.$dirty
              ? _vm.$v.form.start_date.$error
                ? 'is-danger'
                : 'is-success'
              : null,"message":_vm.getFieldError('start_date')}},[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"id":"date-from","placeholder":"Дата не выбрана"},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}})],1),_c('b-field',{attrs:{"label":"Дата проведения по:","type":_vm.$v.form.end_date.$dirty
              ? _vm.$v.form.end_date.$error
                ? 'is-danger'
                : 'is-success'
              : null,"message":_vm.getFieldError('end_date')}},[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"id":"date-to","placeholder":"Дата не выбрана"},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}})],1),_c('b-field',{attrs:{"label":"Город:","type":_vm.$v.form.cities.$dirty
              ? _vm.$v.form.cities.$error
                ? 'is-danger'
                : 'is-success'
              : null,"message":_vm.getFieldError('cities')}},[_c('v-select',{staticStyle:{"min-width":"200px"},attrs:{"label":"name","options":_vm.cities,"placeholder":"Выбрать города","multiple":"","required":""},on:{"input":function($event){return _vm.changeValue($event)}},model:{value:(_vm.form.cities),callback:function ($$v) {_vm.$set(_vm.form, "cities", $$v)},expression:"form.cities"}})],1)],1),_c('div',{staticClass:"column is-two-third",staticStyle:{"border-left":"10px solid #f2f2f2"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"column is-half"},[_c('h3',[_vm._v("Общие условия")]),_c('b-field',{attrs:{"label":"Бренд-листовка","type":_vm.$v.form.image_upload_id.$dirty
                  ? _vm.$v.form.image_upload_id.$error
                    ? 'is-danger'
                    : 'is-success'
                  : null,"message":_vm.getFieldError('image_upload_id')}},[_c('b-upload',{attrs:{"value":_vm.dropFiles,"drag-drop":"","expanded":""},on:{"input":_vm.uploadFile},model:{value:(_vm.dropFiles),callback:function ($$v) {_vm.dropFiles=$$v},expression:"dropFiles"}},[_c('section',{},[_c('div',{staticClass:"content has-text-centered"},[(_vm.form.image_document)?_c('div',{staticStyle:{"text-align":"center","padding":"2rem 1rem","border-bottom":"2px solid #eee"}},[_c('img',{attrs:{"src":_vm.form.image_document.url}})]):_vm._e(),_c('p',[_c('b-icon',{attrs:{"icon":"upload","size":"is-large"}})],1),_c('p',[_vm._v("Бросьте файл сюда или нажмите для загрузки")])])])])],1),_c('div',{staticClass:"tags"},[(_vm.dropFiles)?_c('span',{staticClass:"tag is-primary"},[_vm._v(" "+_vm._s(_vm.dropFiles.name)+" ")]):_vm._e()]),_c('b-field',{attrs:{"label":"Категория","type":_vm.$v.form.category_id.$dirty
                  ? _vm.$v.form.category_id.$error
                    ? 'is-danger'
                    : 'is-success'
                  : null,"message":_vm.getFieldError('category_id')}},[_c('b-select',{attrs:{"scrollable":true,"max-height":200},on:{"input":function($event){return _vm.subList(_vm.form.category_id, _vm.subcategories)}},model:{value:(_vm.form.category_id),callback:function ($$v) {_vm.$set(_vm.form, "category_id", $$v)},expression:"form.category_id"}},_vm._l((_vm.categories),function(category,index){return _c('option',{key:index,domProps:{"value":category.id}},[_c('span',[_vm._v(_vm._s(category.name))])])}),0)],1),_c('b-field',{attrs:{"label":"Условия акции","type":_vm.$v.form.conditions.$dirty
                  ? _vm.$v.form.conditions.$error
                    ? 'is-danger'
                    : 'is-success'
                  : null,"message":_vm.getFieldError('conditions')}},[_c('wysiwyg',{attrs:{"value":_vm.form.conditions,"type":"textarea"},on:{"input":function($event){return _vm.fieldsValue('conditions', $event)}},model:{value:(_vm.form.conditions),callback:function ($$v) {_vm.$set(_vm.form, "conditions", $$v)},expression:"form.conditions"}})],1)],1),_c('div',{staticClass:"column is-half"})]),_c('div',[_c('div',{staticClass:"row"},[_c('b-button',{attrs:{"icon-left":"plus","type":"is-default"},on:{"click":function($event){_vm.show = !_vm.show}}},[_vm._v(" Добавить новый акционный товар ")]),_c('v-select',{staticStyle:{"width":"300px","margin-left":"10px"},attrs:{"label":"name","options":_vm.products_list,"placeholder":"Выбрать существующий товар","required":"","clearable":false},on:{"input":function($event){return _vm.addExistingProducts($event)}}})],1),_c('b-modal',{attrs:{"title":"Добавление товара"},on:{"close":function($event){_vm.show = false}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"addButton",staticStyle:{"margin-left":"20px"},attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.changeValueProducts()}}},[_vm._v(" Добавить ")]),_c('b-button',{staticClass:"addButton",staticStyle:{"background-color":"#476cff","color":"#fff"},on:{"click":function($event){return _vm.changeValueProductsAndContinue()}}},[_vm._v(" Добавить и продолжить ")]),_c('b-button',{attrs:{"label":"Закрыть"},on:{"click":function($event){_vm.show = false}}})]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('section',[_c('b-field',{staticStyle:{"margin":"10px"},attrs:{"label":"Название товара","type":!_vm.nextName
                  ? 'is-danger'
                  : 'is-success',"message":'Поле должно быть заполнено'}},[_c('b-input',{attrs:{"value":_vm.nextName,"placeholder":"Наименование"},model:{value:(_vm.nextName),callback:function ($$v) {_vm.nextName=$$v},expression:"nextName"}})],1),_c('b-field',{staticStyle:{"margin":"10px"},attrs:{"label":"Фото","type":!_vm.productDropFile
                    ? 'is-danger'
                    : 'is-success',"message":'Поле должно быть заполнено'}},[_c('b-upload',{attrs:{"value":_vm.productDropFile,"drag-drop":"","expanded":""},on:{"input":_vm.uploadFileProduct},model:{value:(_vm.productDropFile),callback:function ($$v) {_vm.productDropFile=$$v},expression:"productDropFile"}},[_c('section',{},[_c('div',{staticClass:"content has-text-centered"},[(_vm.form.image_document)?_c('div',{staticStyle:{"text-align":"center","padding":"2rem 1rem","border-bottom":"2px solid #eee"}},[_c('img',{attrs:{"src":_vm.form.image_document.url}})]):_vm._e(),_c('p',[_c('b-icon',{attrs:{"icon":"upload","size":"is-large"}})],1),_c('p',[_vm._v("Бросьте файл сюда или нажмите для загрузки")])])])])],1),_c('div',{staticClass:"tags",staticStyle:{"margin":"10px"}},[(_vm.productDropFile)?_c('span',{staticClass:"tag is-primary"},[_vm._v(" "+_vm._s(_vm.productDropFile.name)+" ")]):_vm._e()]),_c('b-field',{staticStyle:{"margin":"10px"},attrs:{"label":"Подкатегория:","type":_vm.selectedOptionsCat.length==0
                        ? 'is-danger'
                        : 'is-success',"message":'Поле должно быть заполнено'}},[_c('v-select',{attrs:{"label":"name","options":_vm.subCattegories,"placeholder":"Выбрать категориии","multiple":"","required":""},on:{"input":function($event){return _vm.changeValueCategory($event)}},model:{value:(_vm.selectedOptionsCat),callback:function ($$v) {_vm.selectedOptionsCat=$$v},expression:"selectedOptionsCat"}})],1),_c('b-field',{staticStyle:{"margin":"10px"},attrs:{"label":"Шаблоны имен товаров"}},[_c('b-form-textarea',{attrs:{"type":!_vm.productItem.patterns
                      ? 'is-danger'
                      : 'is-success',"message":'Поле должно быть заполнено',"value":_vm.productItem.patterns,"rows":5,"placeholder":"Укажите шаблоны имен товаро в чеках. Можно использовать:\n* для замены любого количества символов\n? для замены одного символа"},model:{value:(_vm.productItem.patterns),callback:function ($$v) {_vm.$set(_vm.productItem, "patterns", $$v)},expression:"productItem.patterns"}})],1)],1)]),_c('b-modal',{attrs:{"title":"Редактирование товара"},on:{"close":function($event){_vm.showItem = false}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"addButton",staticStyle:{"margin-left":"20px"},attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.changeIntemProduct(_vm.productIndex)}}},[_vm._v(" Сохранить ")]),_c('b-button',{attrs:{"label":"Закрыть"},on:{"click":function($event){_vm.showItem = false}}})]},proxy:true}]),model:{value:(_vm.showItem),callback:function ($$v) {_vm.showItem=$$v},expression:"showItem"}},[_c('section',[_c('b-field',{staticStyle:{"margin":"10px"},attrs:{"label":"Название товара","type":!_vm.productItem.name
                    ? 'is-danger'
                    : 'is-success',"message":'Поле должно быть заполнено'}},[_c('b-input',{attrs:{"value":_vm.productItem.name,"placeholder":"Наименование"},model:{value:(_vm.productItem.name),callback:function ($$v) {_vm.$set(_vm.productItem, "name", $$v)},expression:"productItem.name"}})],1),_c('b-field',{staticStyle:{"margin":"10px"},attrs:{"label":"Фото","type":!_vm.productItem.product_image_id
                      ? 'is-danger'
                      : 'is-success',"message":'Поле должно быть заполнено'}},[_c('b-upload',{attrs:{"value":_vm.productDropFile,"drag-drop":"","expanded":""},on:{"input":_vm.uploadFileProduct},model:{value:(_vm.productDropFile),callback:function ($$v) {_vm.productDropFile=$$v},expression:"productDropFile"}},[_c('section',{},[_c('div',{staticClass:"content has-text-centered"},[(_vm.form.image_document)?_c('div',{staticStyle:{"text-align":"center","padding":"2rem 1rem","border-bottom":"2px solid #eee"}},[_c('img',{attrs:{"src":_vm.form.image_document.url}})]):_vm._e(),_c('p',[_c('b-icon',{attrs:{"icon":"upload","size":"is-large"}})],1),_c('p',[_vm._v("Бросьте файл сюда или нажмите для загрузки")])])])])],1),_c('div',{staticClass:"tags",staticStyle:{"margin":"10px"}},[(_vm.productDropFile)?_c('span',{staticClass:"tag is-primary"},[_vm._v(" "+_vm._s(_vm.productDropFile.name)+" ")]):_vm._e()]),_c('b-field',{staticStyle:{"margin":"10px"},attrs:{"label":"Подкатегория:","type":_vm.productItem.sub_category
                        ?_vm.productItem.sub_category.length==0
                          ? 'is-danger'
                          : 'is-success'
                        :null,"message":'Поле должно быть заполнено'}},[_c('v-select',{attrs:{"label":"name","options":_vm.subCattegories,"placeholder":"Выбрать категориии","multiple":"","required":""},on:{"input":function($event){return _vm.changeValueCategory($event)}},model:{value:(_vm.productItem.sub_category),callback:function ($$v) {_vm.$set(_vm.productItem, "sub_category", $$v)},expression:"productItem.sub_category"}})],1),_c('b-field',{staticStyle:{"margin":"10px"},attrs:{"label":"Шаблоны имен товаров"}},[_c('b-form-textarea',{attrs:{"type":!_vm.productItem.patterns
                      ? 'is-danger'
                      : 'is-success',"message":'Поле должно быть заполнено',"value":_vm.productItem.patterns,"rows":5,"placeholder":"Укажите шаблоны имен товаро в чеках. Можно использовать:\n* для замены любого количества символов\n? для замены одного символа"},model:{value:(_vm.productItem.patterns),callback:function ($$v) {_vm.$set(_vm.productItem, "patterns", $$v)},expression:"productItem.patterns"}})],1)],1)])],1)])]),_c('b-field',{attrs:{"label":"Акционные товары","type":_vm.$v.form.products.$dirty
          ? _vm.$v.form.products.$error
            ? 'is-danger'
            : 'is-success'
          : null,"message":_vm.getFieldError('products')}},[_c('b-table',{staticClass:"clickable",attrs:{"striped":"","hoverable":"","detailed":"","detail-key":"id","data":_vm.form.products,"loading":_vm.loading,"paginated":""},on:{"update:loading":function($event){_vm.loading=$event}},scopedSlots:_vm._u([{key:"detail",fn:function(props){return [_c('div',[_c('b-field',{attrs:{"label":"Название товара"}},[_c('b-input',{attrs:{"value":props.row.name,"disabled":""},on:{"input":function($event){return _vm.fieldsValue('name', $event)}},model:{value:(props.row.name),callback:function ($$v) {_vm.$set(props.row, "name", $$v)},expression:"props.row.name"}})],1),_c('b-field',{attrs:{"label":"Фото"}},[(props.row.image_document)?_c('div',{staticStyle:{"text-align":"center","padding":"2rem 1rem","border-bottom":"2px solid #eee"}},[_c('img',{attrs:{"src":props.row.image_document.url}})]):_vm._e()]),_c('b-field',{attrs:{"label":"Подкатегория:"}},_vm._l((props.row.sub_category),function(item,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(item.name)+", ")])}),0),_c('b-field',{attrs:{"label":"Шаблоны имен товаров:"}},[_c('span',{domProps:{"innerHTML":_vm._s(props.row.petterns)}})])],1)]}}])},[_c('b-table-column',{attrs:{"label":"Название товара","width":"100","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.name)+" ")]}}])}),_c('b-table-column',{attrs:{"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('button',{staticClass:"addButton",staticStyle:{"margin-left":"20px","background-color":"#2a22e6","color":"#fff"},on:{"click":function($event){_vm.openProductItem(
                _vm.form.products[props.index],
                props.index,
                (_vm.showItem = !_vm.showItem)
              )}}},[_vm._v(" Редактировать ")])]}}])}),_c('b-table-column',{attrs:{"width":"100","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('button',{staticClass:"addButton",staticStyle:{"margin-left":"20px","background-color":"#f14668","color":"#fff"},on:{"click":function($event){return _vm.removeItem(props.index)}}},[_vm._v(" Удалить ")])]}}])}),_c('template',{slot:"empty"},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"frown","size":"is-large"}})],1),_c('p',[_vm._v("Данные отсутствуют.")])])])])],2)],1),_c('b-field',[_c('b-button',{staticClass:"mt-2",attrs:{"type":"is-info"},on:{"click":function($event){return _vm.submitObject()}}},[_vm._v("Сохранить ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }